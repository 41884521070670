import React from 'react'
import './Basic.scss'
import Menu from "../../components/Menu/Menu"
import BooksContainer from "../../components/Content/Books/BooksContainer"
import About from '../../components/Content/About/About'
import ArticlesList from "../../components/Content/Articles/ArticlesList/ArticlesList";
import Slider from "../../components/Content/Slider/Slider";
import Footer from "../../components/Content/Footer/Footer";



class Basic extends React.Component {

  render() {
    return (
      <div>
        <Menu/>
        <Slider/>
        <About/>
        {/*<About margin={true}/>*/}
        <BooksContainer/>
        <ArticlesList/>
        <Footer/>
      </div>
    );
  }
}

export default Basic