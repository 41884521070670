import React from 'react'
import './Slider.scss'
import Carousel from "react-bootstrap/Carousel";

class Slider extends React.Component {

  render() {
    return (
      <div className={"slider"}>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={window.location.origin + '/img/slider1.jpg'}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={window.location.origin + '/img/slider2.jpg'}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={window.location.origin + '/img/slider3.jpg'}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={window.location.origin + '/img/slider4.jpg'}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={window.location.origin + '/img/slider5.jpg'}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={window.location.origin + '/img/slider6.jpg'}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default Slider