import React from 'react'
import './ArticleContent.scss'
import { withRouter } from 'react-router'
import throttle from "lodash/throttle"
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ArticleContent extends React.Component {
  constructor(props){
    super(props)
    this.state ={
      article: {},
      loading: true,
      numPages: null,
      pageNumber: 1,
      width: null
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  articleList = ["article1" , "article2" , "article3"]

  async getArticleContent() {
    await fetch(window.location.origin + '/articles/articles.json')
      .then(result => result.json())
      .then(articlesJson => {
        articlesJson.articles.forEach( (article) => {
          if(article.id === this.props.id){
            this.setState( {
              article,
              loading: false
            })
          }
        })
      })
  }

  redirectToMain() {
    window.location = '/'
  }

  async componentDidMount() {
    if( this.articleList.includes(this.props.id)){
      await this.getArticleContent()
      this.setDivSize()
      window.addEventListener("resize", throttle(this.setDivSize, 500))
    } else {
      this.props.history.push('/error')
    }

  }

  componentWillUnmount () {
    window.removeEventListener("resize", throttle(this.setDivSize, 500))
  }

  setDivSize = () => {
    this.setState({width: document.getElementById("article-content-id").getBoundingClientRect().width - (document.getElementById("article-content-id").getBoundingClientRect().width * 0.05)})
  }

  render() {
    const { pageNumber, numPages } = this.state;
    if(this.state.loading){
      return(
        <div>
          LOADING
        </div>
      )
    } else {
      return(
        <div className={"article-content-container"}>
          <div className={"article-content-info"}>
            <h3 className={"article-title"}>  { this.state.article.title} </h3>
            <p> { this.state.article.author} </p>
          </div>
          <div id={'article-content-id'} className={"article-content"}>
            {/*<article style={{userSelect: 'none'}}>{ this.state.article.content} </article>*/}
            {/*<iframe src={window.location.origin + "/articles/article1.docx"}/>*/}
            {/*<iframe className={"article-text"} src={window.location.origin + "/articles/" + this.props.id +  ".html"}></iframe>*/}
            <Document
              file={window.location.origin + "/articles/" + this.props.id + ".pdf"}
              onLoadSuccess={this.onDocumentLoadSuccess}
            >
              {Array.from(
                new Array(numPages),
                (el, index) => (
                  <div>
                    <Page
                      className={"page-class"}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={this.state.width}
                    />
                    <hr/>
                  </div>

                ),
              )}
            </Document>
            {/*<p>Page {pageNumber} of {numPages}</p>*/}

          </div>
          <button className={"article-back-button"} onClick={()=> this.redirectToMain()}>Powrót</button>
        </div>
      )
    }

  }
}

ArticleContent = withRouter(ArticleContent)
export default ArticleContent