import React from 'react'
import './ArticleView.scss'
import Menu from "../../components/Menu/Menu";
import ArticleContent from "../../components/Content/Articles/ArticleContent/ArticleContent";

class ArticleView extends React.Component {

  render() {
    return(
      <div>
        <Menu/>
        <ArticleContent id={this.props.match.params.id}/>
      </div>
    );
  }
}

export default ArticleView