import React from 'react'
import './Menu.scss'

class Menu extends React.Component {


  contentArray = [
    {
      divClass: "menuElement", content: "WYDAWNICTWO", onClick: () => {
        this.redirect("about")
      }, alt: "about"
    },
    {
      divClass: "menuElement", content: "KSIĄŻKI", onClick: () => {
        this.redirect("booksMain")
      }, alt: "booksMain"
    },
    {
      divClass: "menuLogo", content: window.location.origin + '/img/logo_pantone.png', alt: "logo", onClick: () => {
        this.scrollToTop()
      }
    },
    {
      divClass: "menuElement", content: "ARTYKUŁY", onClick: () => {
        this.redirect("articlesMain")
      }, alt: "articles"
    },
    {
      divClass: "menuElement", content: "KONTAKT", onClick: () => {
        this.redirect("footer-contact")
      }, alt: "ebooks"
    },
    {
      divClass: "menuIcon", content: window.location.origin + '/img/burger.png', onClick: () => {
        this.toggleMenu()
      }, alt: "icon"
    }


  ]


  toggleMenu() {
    let x = document.getElementById("menu")
    if (x.className === "menuContainer") {
      x.className += " responsive"
    } else {
      x.className = "menuContainer"
    }
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  redirect(variable) {
    window.location = "/#" + variable
    if (document.getElementById('menu').classList.contains('responsive')) {
      window.scrollBy(0, -360);
    } else {
      window.scrollBy(0, -96);
    }

  }

  render() {
    return (

      <div className={"menuContainer"} id={"menu"}>
        {
          this.contentArray.map(({divClass, content, onClick, alt}) => (
            divClass === "menuElement" ?
              <div
                key={alt}
                className={divClass}
                onClick={onClick}
              >
                <p><b> {content}</b></p>
              </div>

              :
              <div
                key={alt}
                className={divClass}
                onClick={onClick}
              >
                <img src={content} alt={alt}/>
              </div>

          ))
        }
      </div>
      /*
           <Navbar sticky="top" bg="light" expand="lg">
              <Navbar.Brand href="#home"><img src={this.contentArray[2].content}
                                              alt={this.contentArray[2].alt}/></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="#home">WYDAWNICTWO</Nav.Link>
                  <Nav.Link href="#booksMain">KSIĄŻKI</Nav.Link>
                  <Nav.Link href="#home">ARTYKUŁY</Nav.Link>
                  <Nav.Link href="#link">EBOOK</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>*/
    );
  }

}

export default Menu