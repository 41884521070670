import React from 'react'
import './ErrorMessage.scss'

class ErrorMessage extends React.Component {

  redirectToMain() {
    window.location = '/'
  }

  render() {
    return(
      <div className={"error-message"}>
        <h2>Przepraszamy</h2>
        <p>Ale podany adres / zasób nie istnieje</p>
        <p>Wróc do strony głównej</p>
        <button className={"back-button"} onClick={()=> this.redirectToMain()}> Powrót </button>
      </div>
    )
  }
}

export default ErrorMessage