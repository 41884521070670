import React from 'react'
import './MapPopUp.scss'

class MapPopUp extends React.Component {


  render() {
    return (
      <div className={"map-popup"}>
        <div className={"map-popup-inner"}>
          <iframe
            className={"map-iframe"}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.4361399776503!2d19.959877051522824!3d50.05939322324446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b3b998822b9%3A0x6dc773a4a3b5b6e1!2sKazimierza+Kordylewskiego+1%2C+31-542+Krak%C3%B3w!5e0!3m2!1sen!2spl!4v1564042957911!5m2!1sen!2spl"
            >Domarton Map</iframe>
          <button onClick={this.props.closePopup}>Zamknij</button>
        </div>
      </div>
    );
  }
}

export default MapPopUp