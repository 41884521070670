import React from 'react'
import './Error.scss'
import ErrorMessage from "../../components/Content/Error/ErrorMessage";



class Error extends React.Component {

  render(){
    return(
      <div>
      <ErrorMessage/>
      </div>
    );
  }

}

export default Error