import React from 'react'
import './BooksContainer.scss'
import { Redirect } from 'react-router-dom'
import BookInfo from './BookInfo/BookInfo'

// import { withRouter } from 'react-router'

class BooksContainer extends React.Component {

  constructor() {
    super();

    this.state = {
      showBookInfo: false,
      bookId: 0
    }

    this.showContent = this.showContent.bind(this)
    this.hideContent = this.hideContent.bind(this)
  }

  contentArray = [
    {
      title: "KOPERNIK I ŻYDZI",
      img: window.location.origin + '/img/kopernik-big.png',
      alt: 'kopernik',
      readMore: () => {
        this.showContent(1)
      },
      buyNow: () => {
        this.buyRedirect("kopernik")
      },
      author: "Daniel Gruber",
      publisher: "DOMARTON",
      binder: "miękka",
      pages: "500",
      size: "164x231x30",
      weight: "760",
      ISBN: "978-83-934565-2-9",
      date: "2019-04-15"
    },
    {
      title: "JUDAIZM MESJANISTYCZNY. W DRODZE DO KOŚCIOŁA CZY DO SYNAGOGI?",
      img: window.location.origin + '/img/judaizm.jpg',
      alt: 'judaizm',
      readMore: () => {
        this.showContent(3)
      },
      buyNow: () => {
        this.buyRedirect("judaizm")
      },
      author: "Artur R. Juszczak",
      publisher: "DOMARTON",
      binder: "twarda",
      pages: "343",
      size: " 167x235x31",
      weight: "700",
      ISBN: "978-83-934565-3-6",
      date: "2019-08-20"
    },
    {
      title: "PRAWA HAMMURABIEGO",
      img: window.location.origin + '/img/prawa-big.png',
      alt: 'prawa',
      readMore: () => {
        this.showContent(2)
      },
      buyNow: () => {
        this.buyRedirect("prawa")
      },
      author: "Josef Klima",
      publisher: "DOMARTON",
      binder: "twarda",
      pages: "307",
      size: "172-236-25",
      weight: "600",
      ISBN: "978-83-934565-1-2",
      date: "2019-04-19"
    },
    {
      title: "TORA I NOWE PRZYMIERZE",
      img: window.location.origin + '/img/tora.jpg',
      alt: 'tora',
      readMore: () => {
        this.showContent(4)
      },
      buyNow: () => {
        this.buyRedirect("tora")
      },
      author: "Daniel Gruber",
      publisher: "DOMARTON",
      binder: "miękka",
      pages: "168",
      size: "135x205",
      weight: "-",
      ISBN: "978-83-934565-5-0",
      date: "2020"
    }

  ]


  buyRedirect(book) {
    switch (book) {
      case "kopernik":
        window.location.href='https://szaron.pl/izrael-zydowska/12588-kopernik-i-%C5%BCydzi-daniel-gruber-wznowienie-9788393456529.html'
        break;
      case "prawa":
        window.location.href='https://szaron.pl/historia/12742-prawa-hammurabiego-9788393456512.html'
        break;
      case "judaizm":
        window.location.href='https://szaron.pl/izrael-zydowska/13263-judaizm-mesjanistyczny-artur-rjuszczak-9788393456536.html'
        break;
      case "tora":
        window.location.href ='https://szaron.pl/izrael-zydowska/13897-tora-i-nowe-przymierze-daniel-gruber-9788393456550.html?search_query=tora+i+nowe+przymierze&results=1058'
        break;
      default:
        break;
    }
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  showContent(id) {
    this.setState(state => ({
      showBookInfo: true,
      bookId: id
    }))

    // this.scrollToTop()
    window.location = "/#booksMain"
    window.scrollBy(0, -120);
  }

  hideContent() {
    this.setState(state => ({
      showBookInfo: false
    }))

    // this.scrollToTop()
    window.location = "/#books"
    window.scrollBy(0, -120);
  }

  render() {
    const {margin} = this.props
    return (

      <div id={"booksMain"} className={"booksContainer"}
           style={margin ? {marginTop: '120px'} : {}}
      >
        <h2> KSIĄŻKI</h2>
        {

          !this.state.showBookInfo ?
            <div className={"books"}>
              {
                this.contentArray.map(({title, img, alt, readMore, buyNow, author, publisher, binder, pages, size, weight, ISBN, date}) => (
                  <div key={alt} className={"book"}>
                    <div className={'book-title'}><p>{title}</p></div>
                    <div className={'book-info'}>
                      <img className={"book-image"} src={img} alt={alt}/>
                      <div>
                        Autor: {author}<br/>
                        Wydawca: {publisher}<br/>
                        Oprawa: {binder}<br/>
                        Ilość Stron: {pages}<br/>
                        Wymiary: {size}<br/>
                        Ciężar: {weight}<br/>
                        ISBN: {ISBN}<br/>
                        Data: {date} <br/>
                      </div>
                    </div>
                    <div className={"books-buttons"}>
                      <button className={'read-buy-button '} onClick={readMore}>czytaj więcej ></button>
                      <button className={'read-buy-button '} onClick={buyNow}>kup teraz ></button>
                    </div>
                  </div>
                ))
              }
            </div> : <BookInfo id={this.state.bookId} hideBookInfo={this.hideContent}/>

          // !this.state.showBookInfo ?
          //   <div className={"books"}>
          //     <Carousel>
          //       {
          //         this.contentArray.map(({title, img, alt, onClick}) => (
          //           <Carousel.Item >
          //             <div >
          //               <div>
          //                 <img
          //                   src={img}
          //                   alt={alt}
          //                 />
          //               </div>
          //               <div>
          //                 <button className={'readMoreButton'} onClick={onClick}>CZYTAJ WIĘCEJ</button>
          //               </div>
          //             </div>
          //           </Carousel.Item>
          //         ))
          //       }
          //     </Carousel>
          //   </div> : null
        }

      </div>
    );
  }
}

export default BooksContainer