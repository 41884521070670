import React from 'react';
import ReactDOM from 'react-dom';
import Basic from "./views/Basic/Basic";
import './index.scss'
// import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ArticleView from "./views/ArticleView/ArticleView";
import Error from "./views/Error/Error"
// import * as serviceWorker from './serviceWorker';
// import { BrowserRouter, Switch, Route } from "react-router-dom";



// ReactDOM.render(
//   <BrowserRouter>
//     <Switch>
//       <Route exact path="/" component={Basic}/>
//     </Switch>
//   </BrowserRouter>
//   , document.getElementById('root'));

ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Basic}/>
        <Route extac path="/index.html" component={Basic} />
        <Route exact path="/article/:id?" component={ArticleView} />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  ,
  document.getElementById("root")
);

// serviceWorker.unregister();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

