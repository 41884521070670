import React from 'react'
import './BookInfo.scss'

class BookInfo extends React.Component {


  contentArray = [
    {
      img: window.location.origin + '/img/kopernik-big.png',
      alt: "kopernik",
      text: "W niniejszej publikacji autor rozprawia na temat błędów nauki i zwodniczości systemów" +
        " myślowych.Zaprasza nas na wędrówkę rozpoczynającą się w czasach Mikołaja Kopernika, " +
        "akończącą w naszym własnym świecie. Nowatorskie spojrzenie na wiarę i religię opiera na " +
        "motywieproblemów    z teorią heliocentryczną, który błyskotliwie łączy z motywem biblijnym " +
        "„wędrujących” (gr. planetai) Izraelitów. Zaskakujące hipotezy skłaniają czytelnika " +
        "do przemyślenia tego co już dawno zostało zatwierdzone, uzgodnione i odłożone na " +
        "niedostępną półkę. W czasach Mikołaja Kopernika niebiosa i wszystko, co w nich było," +
        " obracało się wokół Ziemi. To nie było przekonanie, które trzeba było" +
        " argumentować, to był po prostu obserwowalny fakt. Ludzie mogli to zobaczyć na własne oczy." +
        "Naukowcy, matematycy i filozofowie potwierdzali to. Także doktryna religijna. Był jednak jeden mały problem." +
        " Planety, choć nieistotne, poruszały się nieustannie wbrew ustalonemu systemowi. Rzucając wyzwanie systemowi i" +
        " znajdując prawdziwe centrum, Kopernik rozpoczął rewolucję, która zmieniła życie na ziemi. Ta książka dotyczy" +
        " podobnego błędu konceptualnego we współczesnych systemach. Proponuje inne centrum, które rzuca wyzwanie" +
        " dominującym systemom, ale zestawia i prognozuje dane. Autor przedstawił w niej koncepcje, które zostaną" +
        " przyjęte jedynie przez tych, którzy są gotowi przyswoić sobie nową ideę relacji między Bogiem a człowiekiem.",
      onClick: () => {
        this.buyRedirect("kopernik")
      }
    },
    {
      img: window.location.origin + '/img/prawa-big.png',
      alt: "prawa",
      text: "Najwspanialszy pomnik prawodawstwa starożytnej Mezopotamii… " +
        "To zdanie mogłoby posłużyć za cały wywód. Oddaje ono wielkość, wspaniałość i wyjątkowość Praw Hammurabiego." +
        " Nazywanie ich „pomnikiem” ma również wymiar dosłowny, jako że zostały one doskonale zachowane w postaci tekstu pisma klinowego zamieszczonego na diorytowej steli stanowiącej naczelny eksponat Działu Sztuki Bliskiego Wschodu muzeum w Luwrze." +
        " Ten archeologiczny skarb odzyskany został dla ludzkości na przełomie grudnia 1901 i stycznia 1902 r., podczas prac archeologicznych francuskiej ekipy Jacques;a de Morgana w ruinach starożytnej Suzy." +
        " Nie jest to oczywiście jedyny zachowany zbiór praw pochodzących ze starożytnej Mezopotamii, ale Prawa Hammurabiego stanowią pośród nich wyjątek, jako najlepiej zachowane i odczytane przez naukowców nieomalże w całości." +
        " Mowa zatem o pomniku, który po  dzień dzisiejszy stanowi podstawę do poszukiwania archetypów z różnych obszarów ludzkiej aktywności; nieodzownym narzędziu badawczym dla filologów, religioznawców, historyków, socjologów, a także ekonomistów." +
        " Nie ulega wątpliwości, że dzieło takiego kalibru powinno być udostępniane w postaci przekładu na język polski, a to nie tylko ze względu na treść, ale także na autorów, których bez przesadnej gloryfikacji można nazwać tuzami orientologii." +
        "Autorem czeskiego przekładu Praw Hammurabiego wraz z komentarzem jest Josef Klima (1909 – 1989)." +
        " Czeski naukowiec, asyriolog, historyk prawa klinowego, wieloletni badacz mezopotamskiego piśmiennictwa prawnego, profesor prawa Starożytnego Wschodu na Uniwersytecie Karola w Pradze." +
        " Autorem przekładu na język polski jest natomiast Cezary Kunderewicz (1912 – 1990) polski historyk prawa, papirolog, profesor zw., kierownik Katedry Prawa Rzymskiego na Uniwersytecie Łódzkim." +
        " Jeden z czołowych przedstawicieli tzw. papirologii prawniczej w którego kręgu zainteresowań znalazły się także prawa Starożytnego Wschodu.",
      onClick: () => {
        this.buyRedirect("prawa")
      }
    },
    {
      img: window.location.origin + '/img/judaizm.jpg',
      alt: "judaizm",
      text: "W stosunkowo stabilnym polskim świecie religijnym ożywione dyskusje wywołuje ruch religijny nazywany judaizmem mesjanistycznym," +
        " Ruchem mesjanistyczno-żydowskim lub ruchem Żydów mesjanistycznych. A to dlatego, że pomiędzy Kościołem a Synagogą powstał - kłopotliwy dla" +
        " jednej i drugiej strony – religijny pomost po którym kroczą Żydzi mesjanistyczni. Część z nich podąża w stronę chrześcijaństwa, a część w stronę" +
        " judaizmu. Generuje to pytania dotyczące zarówno natury tego ruchu, jak i tożsamości jego wyznawców." +
        "Jak doszło do jego powstania i dlaczego nazywa się go judaizmem?" +
        "Kim są Żydzi mesjanistyczni i jakie są podstawy ich wiary?" +
        "Odpowiedzi na te pytania zawarte są na kartach niniejszej publikacji. Podczas wieloletnich badań," +
        " dr Artur R. Juszczak wniknął w tę przestrzeń religijną wydobywając treści dotąd nieznane. Po latach chaosu informacyjnego dokonał" +
        " konceptualizacji pojęć i przedstawił definicje umożliwiające właściwy ogląd i obiektywny osąd.",
      onClick: () => {
        this.buyRedirect("judaizm")
      }
    },
    {
      img: window.location.origin + '/img/tora.jpg',
      alt: "tora",
      text: "Dwie wspólnoty wierzących. Jedna wiara, ale dwie różne, a wręcz antagonistyczne wizje." +
        " Członkowie obu tych wspólnot dostrzegali niemożliwy do zażegnania konflikt pomiędzy" +
        " orędownikami zasad osadzonych na Prawie Mojżesza, a Nowym Przymierzem głoszonym" +
        " przez zwolenników Mesjasza z Nazaretu. W czym tkwiło źródło problemu i czy" +
        " dotychczasowe tradycyjne ujęcia nie wprowadzają nas w błąd? Oto okazja do ponownego" +
        " zbadania biblijnego świadectwa w nadziei na rozróżnienie między wieczną mądrością a nagromadzonym błędem. \n" +
        "Daniel Gruber - amerykański filozof, teolog i biblista o pochodzeniu żydowskim. W jego\n" +
        "poglądach widnieje specyficzny dualizm; silnie podkreśla znaczenie fizycznej przynależności\n" +
        "do narodu żydowskiego, ale akcentuje także wagę osoby i dzieła Mesjasza z Nazaretu. Można\n" +
        "jednak śmiało stwierdzić, że pryncypium jego dzieł stanowi naród Izraela; historia,\n" +
        "dziedzictwo Bożych obietnic i niezwykła rola pośród innych narodów.",
      onClick: () => {
        this.buyRedirect("tora")
      }
    }
  ]

  buyRedirect(book) {
    switch (book) {
      case "kopernik":
        window.location.href = 'https://szaron.pl/izrael-zydowska/12588-kopernik-i-%C5%BCydzi-daniel-gruber-wznowienie-9788393456529.html'
        break;
      case "prawa":
        window.location.href = 'https://szaron.pl/historia/12742-prawa-hammurabiego-9788393456512.html'
        break;
      case "judaizm":
        window.location.href='https://szaron.pl/izrael-zydowska/13263-judaizm-mesjanistyczny-artur-rjuszczak-9788393456536.html'
        break;
      case "tora":
        window.location.href ='https://szaron.pl/izrael-zydowska/13897-tora-i-nowe-przymierze-daniel-gruber-9788393456550.html?search_query=tora+i+nowe+przymierze&results=1058'
        break;
      default:
        break;
    }
  }

  readLess() {
    window.location = "/#books"
  }


  render() {
    const {id} = this.props
    const img = this.contentArray[id - 1].img
    const alt = this.contentArray[id - 1].alt
    const text = this.contentArray[id - 1].text
    const onClick = this.contentArray[id - 1].onClick


    return (
      <div id={"currentBook"} className={"bookInfo"}>
        <div className={"book-info-content"}>
          <div className={"bookElement"}>
            <img
              className={"bookElementImg"} src={img} alt={alt}/>
          </div>
          <div className={"bookElement"}>
            {text}
            <div className={"book-info-buttons"}>
              <button className={"buy-back-button"} onClick={() => {
                this.props.hideBookInfo()
              }}>powrót >
              </button>
              <button className={"buy-back-button"} onClick={onClick}> kup teraz ></button>
            </div>
          </div>
        </div>


      </div>
    );
  }
}


export default BookInfo