import React from 'react'
import './ArticlesList.scss'
import { withRouter } from 'react-router'

class ArticlesList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      articles: []
    }
  }

  async getArticleTitles() {
    let resultJson
    console.log(window.location.origin + '/articles/articles.json')
    await fetch(window.location.origin + '/articles/articles.json')
      .then(result => result.json())
      .then(res => {
        resultJson = res
        this.setState(state => ({
          loading: !state.loading,
          articles: resultJson.articles
        }))
      })

  }

  showFullArticle(id) {
    this.props.history.push('article/' + id)
  }

  componentDidMount() {
    this.getArticleTitles()

  }

  render() {

    if (this.state.loading) {
      return (
        <div id={"articlesList"}>
          LOADING
        </div>
      )
    } else {
      return (
        <div id={"articlesMain"} className={"articles-container"}>
          <h2> ARTYKUŁY </h2>
          <div className={"articles"}>
            {this.state.articles.map(({title, author, id}, index) => (
              <div className={"article-info"} key={id}>
                <span
                style={{height: "20%"}}> { index + 1} </span>
                <span
                  style={{height: "40%"}}>{title}</span>
                {/*<span*/}
                   {/*style={{height: "20%"}}> {author} </span>*/}
                <button className={"read-more-button"} onClick={() => this.showFullArticle(id)}>czytaj więcej ></button>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }
}

ArticlesList = withRouter(ArticlesList)
export default ArticlesList