import React from 'react'
import './About.scss'


class About extends React.Component {


  render() {
    return (
      <div id={"about"} className={"container"}
      >
        <h2>WYDAWNICTWO</h2>
        <article>
          Początek wydawnictwa Domarton wyznaczył 2012 rok. Wtedy to niewielka grupa literackich fascynatów
          postanowiła wzbogacić przestrzeń polskiej literatury o pozycję książkowe na temat dynamicznie
          rozwijającego się w USA ruchu mesjanistyczno-żydowskiego. Jeden z najbardziej znanych w Polsce
          myślicieli związanych z tym kierunkiem to Daniel Gruber. I to właśnie jego poczytne dzieło pod tytułem
          Kopernik i Żydzi stało się przyczynkiem do kontynuowanego obecnie zamysłu wydawniczego. Tematyka
          wydawanych przez Domarton publikacji zawiera się w ramach historii, filozofii, teologii i etyki. Plany
          wydawnicze stanowią kontynuacją pierwotnych zamierzeń i przewidują cykliczne publikacje kolejnych
          dzieł Grubera. Ale nie tylko, jako że Polska przestrzeń naukowa wypełnia się kolejnymi opracowaniami
          na tematy judaizmu mesjanistycznego i problematyki tzw. „parting of the ways” czyli historycznych i
          doktrynalnych przyczyn rozejścia się dróg Kościoła i Synagogi.
          Tematy te stanowią przedmiot badań
          Artura R. Juszczaka którego dwa tytuły ukażą się do końca 2019 roku. Wydawnictwo zrealizowało też
          zamysł powtórnego wydania publikacji która po ponad 60 latach uzyskała status białego kruka. Mowa o
          kolejnym wydaniu pozycji Prawa Hammurabiego, którą opracowali naukowcy bez wątpienia nazywani
          tuzami europejskiej orientalistyki.
          Wydawnictwo poszukuje nowych wyzwań którymi jawią się dysertacje doktorskie. Mając na uwadze
          wkład w polską naukę oferujemy naszym naukowcom szansę zaistnienia na wydawniczym rynku.
        </article>

      </div>
    );
  }
}


export default About




//
// <div className={"div-about"}>
//   <div className={"left-top "}>
//   <article>
//   Początek wydawnictwa Domarton wyznaczył 2012 rok. Wtedy to niewielka grupa literackich fascynatów
// postanowiła wzbogacić przestrzeń polskiej literatury o pozycję książkowe na temat dynamicznie
// rozwijającego się w USA ruchu mesjanistyczno-żydowskiego. Jeden z najbardziej znanych w Polsce
// myślicieli związanych z tym kierunkiem to Daniel Gruber. I to właśnie jego poczytne dzieło pod tytułem
// Kopernik i Żydzi stało się przyczynkiem do kontynuowanego obecnie zamysłu wydawniczego. Tematyka
// wydawanych przez Domarton publikacji zawiera się w ramach historii, filozofii, teologii i etyki. Plany
// wydawnicze stanowią kontynuacją pierwotnych zamierzeń i przewidują cykliczne publikacje kolejnych
// dzieł Grubera. Ale nie tylko, jako że Polska przestrzeń naukowa wypełnia się kolejnymi opracowaniami
// na tematy judaizmu mesjanistycznego i problematyki tzw. „parting of the ways” czyli historycznych i
// doktrynalnych przyczyn rozejścia się dróg Kościoła i Synagogi.
// </article>
// </div>
// <div className={"right-top"}>
//   <img src={ window.location.origin + '/img/zydzi.png'} alt={"cos tam"} />
// </div>
// </div>
// <div className={"div-about"}>
//   <div className={"left-bottom "}>
//
//   </div>
//   <div className={"right-bottom"}>
//     <article>
//       Tematy te stanowią przedmiot badań
//       Artura R. Juszczaka którego dwa tytuły ukażą się do końca 2019 roku. Wydawnictwo zrealizowało też
//       zamysł powtórnego wydania publikacji która po ponad 60 latach uzyskała status białego kruka. Mowa o
//       kolejnym wydaniu pozycji Prawa Hammurabiego, którą opracowali naukowcy bez wątpienia nazywani
//       tuzami europejskiej orientalistyki.
//       Wydawnictwo poszukuje nowych wyzwań którymi jawią się dysertacje doktorskie. Mając na uwadze
//       wkład w polską naukę oferujemy naszym naukowcom szansę zaistnienia na wydawniczym rynku.
//     </article>
//   </div>
// </div>