import React from 'react'
import './Footer.scss'
import MapPopUp from "../MapPopUp/MapPopUp";

class Footer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showPopup: false
    }

    this.togglePopup = this.togglePopup.bind(this)
  }

  togglePopup(){
    console.log("popup")
    console.log(this.state.showPopup)
    this.setState( state => ({
      showPopup: !state.showPopup
    }))

    console.log(this.state.showPopup)
  }

  render() {
    return (
      <div id={"footer-contact"} className={"footer-container"}>
        <h2>KONTAKT</h2>
        <h3 className={"publisher"}>WYDAWNICTWO DOMARTON</h3>
        <div className={"footer-emails"}>
          <div className={"footer-mail"}>
            <p className={"contact"}><img className={"icons"} src={window.location.origin + "/img/calendar-icon.svg"}
                                          alt={"e-mail :"}/> Poniedziałek - Piątek</p>
            <p className={"contact"}><img className={"icons"} src={window.location.origin + "/img/clock-icon.svg"}
                                          alt={"nr tel. :"}/> 10:00 - 16:00</p>
          </div>
          <div className={"footer-mail"}>
            <p className={"contact"}><img className={"icons"} src={window.location.origin + "/img/phone-icon.svg"}
                                          alt={"telefon :"} /> +48 606 321 322</p>
            <p className={"contact"}><img className={"icons"} src={window.location.origin + "/img/email-icon.svg"}
                                          alt={"e-mail :"}/>domarton@onet.pl</p>
            <p className={"contact"}><img className={"icons"} src={window.location.origin + "/img/place-icon.svg"}
                                          alt={"adres :"} onClick={this.togglePopup}/>ul. K. Kordylewskiego 1/2, 31-542 Kraków</p>
          </div>
        </div>
        { this.state.showPopup ?
          <MapPopUp closePopup={this.togglePopup}/>
          : null
        }
        <p className={"copyright"}>Copyright Domarton 2019</p>
      </div>
    );
  }
}

export default Footer
